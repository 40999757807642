import React, { useEffect, useState } from "react"
import { Button } from "../../components/ui"
import { ArrowRight } from "react-feather"
import { Link } from "gatsby"

const Row = ({ children }) => {
    return (
        <div className="row block lg:flex items-center justify-content my-3">
            {children}
        </div>
    )
}

const Col = ({ children }) => {
    return <div className="flex-1 p-2">{children}</div>
}

const LinkButton = ({ to, event, children }) => {
    return (
        <div className="items-center justify-content">
            <a href={to}>
                <Button
                    title={children}
                    type="button"
                    iconRight={<ArrowRight />}
                />
            </a>
        </div>
    )
}
const DKSLink = ({ to, event, children }) => {
    const isDocument = typeof document !== "undefined"

    return (
        <div className="items-center justify-content">
            {isDocument && document.URL.match("den") ? (
                <a href="https://free.nikkei4946.com/?scode=101563&waad=D5RLBpfh">
                    <Button
                        title={children}
                        type="button"
                        iconRight={<ArrowRight />}
                    />
                </a>
            ) : (
                <a href={to}>
                    <Button
                        title={children}
                        type="button"
                        iconRight={<ArrowRight />}
                    />
                </a>
            )}
        </div>
    )
}
const Popup = ({ to, children }) => {
    const [scrolled, changeState] = useState(false)

    useEffect(() => {
        const onScroll = () => {
            if (document.documentElement.scrollTop > 80 && !scrolled) {
                changeState(true)
            } else if (document.documentElement.scrollTop <= 80 && scrolled)
                changeState(false)
        }

        window.addEventListener("scroll", onScroll)

        return () => {
            window.removeEventListener("scroll", onScroll)
        }
    }, [scrolled])

    return (
        <React.Fragment>
            <div
                className={`sm:hidden duration-300 transition-all flex justify-center sm:justify-end items-center z-50 fixed w-full left-0 nav  ${
                    scrolled ? "open" : "close"
                }`}
            >
                <div className="">
                    <a href={to}>
                        <Button
                            title={children}
                            type="button"
                            // iconRight={<ArrowRight />}
                            aria-label={children}
                        />
                    </a>
                </div>
                <div className="absolute line h-px left-0 bottom-0 bg-gradient-primary"></div>
            </div>
            {/* {navPlaceholder && (
                <div style={{ height: `${navbarHeight}px` }}></div>
            )} */}
        </React.Fragment>
    )
}
const FooterMenu = () => {
    return (
        <React.Fragment>
            <div className="sm:hidden duration-300 transition-all flex justify-end items-center z-50 fixed w-full bottom-0 left-0 nav">
                <div className="pr-3">
                    <Link to="/contact">
                        <Button
                            title="配達・新聞に関するご連絡"
                            type="button"
                            // iconRight={<ArrowRight />}
                            aria-label="配達・新聞に関するご連絡"
                        />
                    </Link>
                </div>
                <div className="absolute line h-px left-0 bottom-0 bg-gradient-primary"></div>
            </div>
        </React.Fragment>
    )
}
export { Row, Col, LinkButton, DKSLink, Popup, FooterMenu }
